import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/MainLayout.vue'
import { AmplifyEventBus } from 'aws-amplify-vue'
import { getUser } from '../utils/auth.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: '/home',
      name: 'home',
      component: () =>
        import('../views/pages/Home.vue')
    },
    {
      path: '/products',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, tab: route.query.tab, field: route.query.field, sort: route.query.sort }),
      name: 'products',
      component: () =>
        import('../views/pages/product/productList.vue')
    },
    {
      path: '/productSupplierList',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, tab: route.query.tab, field: route.query.field, sort: route.query.sort }),
      name: 'productSupplierList',
      component: () =>
        import('../views/pages/product/productSupplierList.vue')
    },
    {
      path: '/page-list',
      name: 'pageList',
      component: () =>
        import('../views/pages/page/pageList.vue')
    },
    {
      path: '/add-page',
      name: 'addPage',
      component: () =>
        import('../views/pages/page/components/addPage.vue')
    },
    {
      path: '/page-detail/:slug',
      name: 'detailPage',
      component: () =>
        import('../views/pages/page/detailPage.vue')
    },
    {
      path: '/update-product/:product_id',
      name: 'updateProduct',
      component: () =>
        import('../views/pages/product/productUpdateForm.vue')
    },
    {
      path: '/add-product',
      name: 'addProduct',
      component: () =>
        import('../views/pages/product/productForm.vue')
    },
    {
      path: '/supplierList',
      name: 'supplierList',
      component: () =>
        import('../views/pages/priceApproval/supplierList.vue')
    },
    {
      path: '/supplierDetail',
      name: 'supplierDetail',
      component: () =>
        import('../views/pages/priceApproval/supplierDetail.vue')
    },
    {
      path: '/product-detail',
      name: 'productDetail',
      component: () =>
        import('../views/pages/product/productDetail.vue')
    },
    {
      path: '/add-price',
      name: 'addPrice',
      component: () =>
        import('../views/pages/priceApproval/addPrice.vue')
    },
    {
      path: '/category',
      name: 'category',
      component: () =>
        import('../views/pages/category/categoryList.vue')
    },
    {
      path: '/inventory',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, field: route.query.field, sort: route.query.sort }),
      name: 'inventory',
      component: () =>
        import('../views/pages/inventory/stockList.vue')
    },
    {
      path: '/stock-detail/:variant_id',
      name: 'stockDetail',
      component: () =>
        import('../views/pages/inventory/stockDetail.vue')
    },
    {
      path: '/transfer',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, tab: route.query.tab }),
      name: 'transfer',
      component: () => import('../views/pages/transfer/transferList.vue')
    },
    {
      path: '/add-transfer',
      name: 'addTransfer',
      component: () => import('../views/pages/transfer/transferForm.vue')
    },
    {
      path: '/edit-transfer/:transfer_id',
      name: 'editTransfer',
      component: () => import('../views/pages/transfer/editTransfer.vue')
    },
    {
      path: '/transferDetail/:transfer_id',
      name: 'transferDetail',
      component: () => import('../views/pages/transfer/transferDetail.vue')
    },
    {
      path: '/receive-transfer-detail/:transfer_id',
      name: 'receiveTransferDetail',
      component: () => import('../views/pages/transfer/receiveTransferDetail.vue')
    },
    {
      path: '/supplier',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, field: route.query.field, sort: route.query.sort }),
      name: 'supplier',
      component: () =>
        import('../views/pages/supplier/supplierList.vue')
    },
    {
      path: '/supplier-add',
      name: 'supplierAddForm',
      component: () =>
        import('../views/pages/supplier/supplierAddForm.vue')
    },
    {
      path: '/supplier-admin-list',
      props: route => ({ page: route.query.page, size: route.query.size }),
      name: 'supplierAdminList',
      component: () =>
        import('../views/pages/supplierAdmin/supplierAdminList.vue')
    },
    {
      path: '/edit-supplier-admin/:user_name',
      name: 'editSupplierAdmin',
      component: () =>
        import('../views/pages/supplierAdmin/editSupplierAdmin.vue')
    },
    {
      path: '/edit-supplier/:supplier_id',
      name: 'editSupplier',
      component: () =>
        import('../views/pages/supplier/supplierUpdateForm.vue')
    },
    {
      path: '/edit-color/:color_id',
      name: 'editColor',
      component: () =>
        import('../views/pages/color/colorUpdateForm.vue')
    },
    {
      path: '/colorList',
      name: 'colorList',
      component: () =>
        import('../views/pages/color/colorList.vue')
    },
    {
      path: '/color-add',
      name: 'colorAddForm',
      component: () =>
        import('../views/pages/color/colorAddForm.vue')
    },
    {
      path: '/update-tag/:id',
      name: 'updateTag',
      component: () =>
        import('../views/pages/tag/updateTag.vue')
    },
    {
      path: '/add-tag',
      name: 'addTag',
      component: () =>
        import('../views/pages/tag/addTag.vue')
    },
    {
      path: '/tagList',
      name: 'tagList',
      component: () =>
        import('../views/pages/tag/tagList.vue')
    },
    {
      path: '/brandList',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, field: route.query.field, sort: route.query.sort }),
      name: 'brandList',
      component: () =>
        import('../views/pages/brand/brandList.vue')
    },
    {
      path: '/brand-add',
      name: 'brandAddForm',
      component: () =>
        import('../views/pages/brand/brandAddForm.vue')
    },
    {
      path: '/edit-brand/:brand_id',
      name: 'editBrand',
      component: () =>
        import('../views/pages/brand/brandUpdateForm.vue')
    },
    {
      path: '/userList',
      name: 'userList',
      component: () =>
        import('../views/pages/user/userList.vue')
    },
    {
      path: '/code-discount',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, channel: route.query.channel, status: route.query.status }),
      name: 'codeDiscount',
      component: () =>
        import('../views/pages/discount/codeDiscount.vue')
    },
    {
      path: '/auto-discount',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, channel: route.query.channel, status: route.query.status }),
      name: 'autoDiscount',
      component: () =>
        import('../views/pages/discount/autoDiscount.vue')
    },
    {
      path: '/add-discount',
      name: 'addDiscount',
      component: () =>
        import('../views/pages/discount/addDiscount.vue')
    },
    {
      path: '/detail-discount/:id',
      name: 'detailDiscount',
      component: () =>
        import('../views/pages/discount/detailDiscount.vue')
    },
    {
      path: '/customer-manager-list',
      name: 'customerManagerList',
      component: () =>
        import('../views/pages/customerManager/customerManagerList.vue')
    },
    {
      path: '/add-customer-manager',
      name: 'addCustomerManager',
      component: () =>
        import('../views/pages/customerManager/addCustomerManager.vue')
    },
    {
      path: '/customer-manager-edit/:employee_id',
      name: 'customerManagerEdit',
      component: () =>
        import('../views/pages/customerManager/customerManagerEdit.vue')
    },
    {
      path: '/banner-list',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, tab: route.query.tab }),
      name: 'bannerList',
      component: () =>
        import('../views/pages/banner/bannerList.vue')
    },
    {
      path: '/add-banner',
      name: 'addBanner',
      component: () =>
        import('../views/pages/banner/addBanner.vue')
    },
    {
      path: '/banner-edit/:id',
      name: 'bannerEdit',
      component: () =>
        import('../views/pages/banner/bannerEdit.vue')
    },
    {
      path: '/banner-detail/:id',
      name: 'bannerDetail',
      component: () =>
        import('../views/pages/banner/bannerDetail.vue')
    },
    {
      path: '/add-block',
      name: 'addBlock',
      component: () =>
        import('../views/pages/block/blockAddForm.vue')
    },
    {
      path: '/block-list',
      props: route => ({ page: route.query.page, size: route.query.size, search: route.query.search, tab: route.query.tab }),
      name: 'blockList',
      component: () =>
        import('../views/pages/block/blockList.vue')
    },
    {
      path: '/block-edit/:id',
      name: 'blockEdit',
      component: () =>
        import('../views/pages/block/blockUpdateForm.vue')
    },
    {
      path: '/collection-list',
      name: 'collectionList',
      component: () =>
        import('../views/pages/Collection/collectionList.vue')
    },
    {
      path: '/add-collection',
      name: 'addCollection',
      component: () =>
        import('../views/pages/Collection/addCollection.vue')
    },
    {
      path: '/collection-edit/:id',
      name: 'collectionEdit',
      component: () =>
        import('../views/pages/Collection/collectionEdit.vue')
    }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/signIn',
    name: 'signIn',
    component: () =>
      import('../views/pages/user/login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: () =>
      import('../views/pages/user/signUp.vue'),
    meta: { requiresAuth: false }
  }
  ]
})

AmplifyEventBus.$on('authState', async state => {
  const pushPathes = {
    signedOut: () => {
      router.push({ path: '/signIn' })
    },
    signUp: () => {
      router.push({ path: '/signUp' })
    },
    // confirmSignUp: () => {
    //   router.push({ path: '/signUpConfirm' })
    // },
    signIn: () => {
      router.push({ path: '/signIn' })
    },
    signedIn: () => {
      router.push({ path: '/home' })
    }
  }
  if (typeof pushPathes[state] === 'function') {
    pushPathes[state]()
  }
})

router.beforeResolve(async (to, from, next) => {
  const user = await getUser()
  if (!user) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      return next({
        path: '/signIn'
      })
    }
  } else {
    if (
      to.matched.some(
        record =>
          typeof record.meta.requiresAuth === 'boolean' &&
                !record.meta.requiresAuth
      )
    ) {
      return next({
        path: '/'
      })
    }
    if (to.name == null) {
      return next({
        path: '/'
      })
    }
  }
  return next()
})

export default router
