// URL
const BASE_PATH = 'https://rest.toktok.mn/inventory'
// const BASE_PATH = 'http://localhost:3000/dev/inventory-dev'
const BASE_SECOND_PATH = 'https://api.toktokmall.mn/api'
const BASE_CHAT_PATH = 'http://chat-api.toktokmall.mn'

// Inventory
const BASE_INVENTORY = '/inventory'

// chat
const BASE_CHAT_ROOM = '/room'
const BASE_CHAT_CHAT_SEND = '/chat/send'

// Product
const BASE_PRODUCT = '/product'
const BASE_HISTORY = '/history'
const BASE_PRODUCTS_WITH_VARIANT = '/getProductsWithVariant'
const BASE_BRAND_PRODUCTS_WITH_VARIANT = '/brand/getProductByBrand'
const BASE_GET_ONE_PRODUCT = '/getOneProduct'
const BASE_GET_PRODUCT_BY_VARIANT_ID = '/getProductSameVariants'
const BASE_GET_SEARCH_PRODUCT_NAME = '/getSearchProductName'
const BASE_GET_OPTIONS_NAME = '/getOptionsName'
const BASE_GET_OPTIONS_VALUE = '/getOptionsValue '
const BASE_GET_PROPRTY_NAME = '/getPropertiesName'
const BASE_GET_PROPRTY_VALUE = '/getPropertiesValue'
const BASE_GET_ALL_PRODUCT = '/getAllProducts'
const BASE_POST_ACCEPT_PRICE = '/acceptedPrice'

// Excel
const BASE_PRODUCTS_EXEL_DOWNLOAD = '/productsExcelDownload'
// Page
const BASE_PAGE = '/page'

// Category
const BASE_CATEGORY = '/category'
const BASE_SORT_CATEGORY = '/updateAllCategorySort'
// Tag
const BASE_TAG = '/tag'

// Supplier
const BASE_SUPPLIER = '/supplier'
const BASE_GET_ONESUPPLIER_BRANDS = '/getSupplierBrands'
const BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID = '/getSuppliersByWarehouseId'
const BASE_GET_BRANDS_BY_SUPLLIER_IDS = '/getBrandsBySupplierIds'

// SupplierAdmin
const GET_SUPPLIER_ADMINS = '/client/getUsers'
const GET_SUPPLIER_ADMIN = '/client/getUser'
const UPDATE_SUPPLIER_ADMIN = '/client/updateSupplierIds'
const CONFIRM_SUPPLIER_ADMIN = '/client/confirmSignUp'
const REMOVE_SUPPLIER_ADMIN = '/client/removeUser'
const CHANGE_SUPPLIER_ADMIN = '/client/setPassword'

// Variant
const BASE_ADD_VARIANT = '/addVariant'
const BASE_UPDATE_VARIANT = '/updateVariant'
const BASE_GET_ALL_VARIANTS = '/getAllVariants'
const BASE_CHANGE_PRODUCT_COUNT = '/changeCount'
const GET_EXPIRED_VARIANTS = '/getExpiredVariants'
const BASE_SET_VARIANT_RACK_POSITION = '/setVariantRackPosition'
const BASE_SET_VARIANT_BARCODE_AND_EXPIREDDATE = '/setVariantBarCodeAndExpiredDate'

// Transfer
const BASE_TRANSFER = '/transfer'
const BASE_UNCHECKED_TRANSFER_PRODUCTS = '/getProductsByTransfer'
const BASE_CHANGE_TRANSFER_STATUS = '/changeTransferStatus'
const BASE_GET_ALL_REQURIED_PRODUCT = '/getAllRequiredProduct'

// RETURN
const BASE_RETURN = '/return'
const BASE_UNCHECKED_RETURN_PRODUCTS = '/getProductsByReturn'
const BASE_CHANGE_RETURN_STATUS = '/changeReturnStatus'

// Warehouse
const BASE_WAREHOUSE = '/warehouse'
const BASE_GET_WAREHOUSES_BY_SUPPLIER_ID = '/getWarehousesBySupplierId'

// Color
const BASE_COLOR = '/color'

// Brand
const BASE_BRAND = '/brand'
const BASE_GET_BRANDS_BY_SUPPLIER_ID = '/getSupplierBrands'

// Utillity
const BASE_IMAGE_UPLOAD = '/file/imageUpload'

// Stats
const GET_STATS = '/getStats'

// Users
const BASE_GET_USERS = '/user/getUsers'
const BASE_CHANGE_PASSWORD = '/changePassword'
// Hariltsagch hariutssan meneger
const BASE_EMPLOYEE = '/employee'
// Groups
const BASE_GET_GROUPS = '/user/getGroups'
const BASE_CREATE_GROUP = '/user/createGroup'
const BASE_DELETE_GROUP = '/user/deleteGroup'
const BASE_ADD_TO_GROUP = '/user/addUserToGroup'

// Order
const BASE_ORDER = '/order'
const BASE_ORDERS_DETAILS = '/getOrdersDetails'
const BASE_ORDER_FULLFILLMENT = '/updateOrderItem'

// Basket
const BASE_CREATE_BASKET = '/basket'
const BASE_ADD_BASKET_ITEM = '/addProduct'
const BASE_SET_BASKET_ITEMS = '/addProducts'
const BASE_REMOVE_BASKET_ITEM = '/removeProduct'
const BASE_CREATE_ORDER = '/basketToOrder'
const BASE_ADD_USER = '/setEmailPhone'
const BASE_ADD_ADDRESS = '/setAddress'
const BASE_ADD_DISCOUNT_TO_BASKET = '/addCodeDiscountBasket'
const BASE_REMOVE_DISCOUNT_TO_BASKET = '/removeDiscountFromBasket'
// Discount
const BASE_DISCOUNT = '/discount'
const BASE_ADD_DISCOUNT = '/discount'
const BASE_GET_ONE_DISCOUNT = '/discount'
// Banner
const BASE_BANNER = '/banner'

// SuperBlock
const SUPER_BLOCK = '/superBlock'

// Address
const BASE_ADDRESS = '/addressSearch'

// search_text
const BASE_SEARCH_TEXT = '/supplier'
// collection
const BASE_COLLECTION = '/collection'
const BASE_GET_COLLECTION_LIST = '/getCollectionList'
// admin udirdlaga
const BASE_CLIENT = '/client'
const BASE_GET_USER = '/getUser'

export default {
  GET_SUPPLIER_ADMIN: GET_SUPPLIER_ADMIN,
  UPDATE_SUPPLIER_ADMIN: UPDATE_SUPPLIER_ADMIN,
  CONFIRM_SUPPLIER_ADMIN: CONFIRM_SUPPLIER_ADMIN,
  GET_SUPPLIER_ADMINS: GET_SUPPLIER_ADMINS,
  REMOVE_SUPPLIER_ADMIN: REMOVE_SUPPLIER_ADMIN,
  CHANGE_SUPPLIER_ADMIN: CHANGE_SUPPLIER_ADMIN,

  BASE_PATH: BASE_PATH,
  BASE_SECOND_PATH: BASE_SECOND_PATH,
  BASE_CHAT_PATH: BASE_CHAT_PATH,
  //
  BASE_INVENTORY: BASE_INVENTORY,
  //
  BASE_HISTORY: BASE_HISTORY,
  //
  BASE_PRODUCT: BASE_PRODUCT,
  BASE_PRODUCTS_WITH_VARIANT: BASE_PRODUCTS_WITH_VARIANT,
  BASE_BRAND_PRODUCTS_WITH_VARIANT: BASE_BRAND_PRODUCTS_WITH_VARIANT,
  BASE_GET_ONE_PRODUCT: BASE_GET_ONE_PRODUCT,
  BASE_GET_PRODUCT_BY_VARIANT_ID: BASE_GET_PRODUCT_BY_VARIANT_ID,
  BASE_GET_SEARCH_PRODUCT_NAME: BASE_GET_SEARCH_PRODUCT_NAME,
  BASE_GET_OPTIONS_NAME: BASE_GET_OPTIONS_NAME,
  BASE_GET_OPTIONS_VALUE: BASE_GET_OPTIONS_VALUE,
  BASE_GET_PROPRTY_NAME: BASE_GET_PROPRTY_NAME,
  BASE_GET_PROPRTY_VALUE: BASE_GET_PROPRTY_VALUE,
  BASE_GET_ALL_PRODUCT: BASE_GET_ALL_PRODUCT,
  BASE_POST_ACCEPT_PRICE: BASE_POST_ACCEPT_PRICE,
  //
  BASE_PAGE: BASE_PAGE,
  //
  BASE_PRODUCTS_EXEL_DOWNLOAD: BASE_PRODUCTS_EXEL_DOWNLOAD,
  //
  BASE_CATEGORY: BASE_CATEGORY,
  BASE_SORT_CATEGORY: BASE_SORT_CATEGORY,
  //
  BASE_SUPPLIER: BASE_SUPPLIER,
  BASE_GET_ONESUPPLIER_BRANDS: BASE_GET_ONESUPPLIER_BRANDS,
  BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID: BASE_GET_SUPPLIERS_BY_WAREHOUSE_ID,
  BASE_GET_BRANDS_BY_SUPLLIER_IDS: BASE_GET_BRANDS_BY_SUPLLIER_IDS,
  //
  BASE_ADD_VARIANT: BASE_ADD_VARIANT,
  BASE_UPDATE_VARIANT: BASE_UPDATE_VARIANT,
  BASE_GET_ALL_VARIANTS: BASE_GET_ALL_VARIANTS,
  BASE_CHANGE_PRODUCT_COUNT: BASE_CHANGE_PRODUCT_COUNT,
  GET_EXPIRED_VARIANTS: GET_EXPIRED_VARIANTS,
  BASE_SET_VARIANT_RACK_POSITION: BASE_SET_VARIANT_RACK_POSITION,
  BASE_SET_VARIANT_BARCODE_AND_EXPIREDDATE: BASE_SET_VARIANT_BARCODE_AND_EXPIREDDATE,
  //
  BASE_TRANSFER: BASE_TRANSFER,
  BASE_UNCHECKED_TRANSFER_PRODUCTS: BASE_UNCHECKED_TRANSFER_PRODUCTS,
  BASE_CHANGE_TRANSFER_STATUS: BASE_CHANGE_TRANSFER_STATUS,
  BASE_GET_ALL_REQURIED_PRODUCT: BASE_GET_ALL_REQURIED_PRODUCT,
  //
  BASE_RETURN: BASE_RETURN,
  BASE_UNCHECKED_RETURN_PRODUCTS: BASE_UNCHECKED_RETURN_PRODUCTS,
  BASE_CHANGE_RETURN_STATUS: BASE_CHANGE_RETURN_STATUS,
  //
  BASE_WAREHOUSE: BASE_WAREHOUSE,
  BASE_GET_WAREHOUSES_BY_SUPPLIER_ID: BASE_GET_WAREHOUSES_BY_SUPPLIER_ID,
  //
  BASE_COLOR: BASE_COLOR,
  //
  BASE_TAG: BASE_TAG,
  //
  BASE_BRAND: BASE_BRAND,
  //
  GET_STATS: GET_STATS,
  //
  BASE_GET_BRANDS_BY_SUPPLIER_ID: BASE_GET_BRANDS_BY_SUPPLIER_ID,
  BASE_IMAGE_UPLOAD: BASE_IMAGE_UPLOAD,
  //
  BASE_GET_USERS: BASE_GET_USERS,
  BASE_CHANGE_PASSWORD: BASE_CHANGE_PASSWORD,
  //
  BASE_GET_GROUPS: BASE_GET_GROUPS,
  BASE_CREATE_GROUP: BASE_CREATE_GROUP,
  BASE_DELETE_GROUP: BASE_DELETE_GROUP,
  BASE_ADD_TO_GROUP: BASE_ADD_TO_GROUP,
  //
  BASE_ORDER: BASE_ORDER,
  BASE_ORDERS_DETAILS: BASE_ORDERS_DETAILS,
  BASE_ORDER_FULLFILLMENT: BASE_ORDER_FULLFILLMENT,
  //
  BASE_CREATE_BASKET: BASE_CREATE_BASKET,
  BASE_ADD_BASKET_ITEM: BASE_ADD_BASKET_ITEM,
  BASE_SET_BASKET_ITEMS: BASE_SET_BASKET_ITEMS,
  BASE_REMOVE_BASKET_ITEM: BASE_REMOVE_BASKET_ITEM,
  BASE_CREATE_ORDER: BASE_CREATE_ORDER,
  BASE_ADD_USER: BASE_ADD_USER,
  BASE_ADD_ADDRESS: BASE_ADD_ADDRESS,
  BASE_ADD_DISCOUNT_TO_BASKET: BASE_ADD_DISCOUNT_TO_BASKET,
  BASE_REMOVE_DISCOUNT_TO_BASKET: BASE_REMOVE_DISCOUNT_TO_BASKET,
  //
  BASE_DISCOUNT: BASE_DISCOUNT,
  BASE_ADD_DISCOUNT: BASE_ADD_DISCOUNT,
  BASE_GET_ONE_DISCOUNT: BASE_GET_ONE_DISCOUNT,
  //
  BASE_BANNER: BASE_BANNER,
  //
  SUPER_BLOCK: SUPER_BLOCK,
  //
  BASE_ADDRESS: BASE_ADDRESS,
  //
  BASE_SEARCH_TEXT: BASE_SEARCH_TEXT,
  //
  BASE_COLLECTION: BASE_COLLECTION,
  BASE_GET_COLLECTION_LIST: BASE_GET_COLLECTION_LIST,
  //
  BASE_CHAT_ROOM: BASE_CHAT_ROOM,
  BASE_CHAT_CHAT_SEND: BASE_CHAT_CHAT_SEND,
  //
  BASE_CLIENT: BASE_CLIENT,
  BASE_GET_USER: BASE_GET_USER,
  //
  BASE_EMPLOYEE: BASE_EMPLOYEE
}
