<template>
  <el-aside :class="isSun ? 'main-sidebar' : 'main-sidebar1'">
    <ul class="main-menu">
      <li :class="(($route.name === 'home') ? 'active': '')">
        <router-link to="/">
          <home-icon class="icon" :size="24" />
          <span class="text">Нүүр</span>
        </router-link>
      </li>
      <li :class="(( [
          'pageList',
          'addPage',
          'detailPage'].includes($route.name )) ? 'active': '')">
        <router-link to="/page-list">
          <page-next-icon class="icon" :size="24" />
          <span class="text">Хуудас</span>
        </router-link>
      </li>
      <li :class="(( [
          'category',
          'supplierAddForm',
          'warehouseList',
          'addWarehouse',
          'editWarehouse',
          'colorList',
          'tagList',
          'addTag',
          'brandList',
          'editSupplier',
          'brandAddForm',
          'editBrand',
          'supplier',
          'userList'].includes($route.name )) ? 'active': '')">
        <router-link to="/category">
          <registry-icon class="icon" :size="24" />
          <span class="text">Апп удирдлага</span>
        </router-link>
        <ul>
          <li :class="(( ['category'].includes($route.name )) ? 'active': '')">
            <router-link to="/category">
              <span class="text">Категори</span>
            </router-link>
          </li>
          <li :class="(([
          'supplier',
          'editSupplier',
          'supplierAddForm'].includes($route.name )) ? 'active': '')">
            <router-link to="/supplier">
              <span class="text">Нийлүүлэгч</span>
            </router-link>
          </li>
          <li :class="(([
          'colorList',
          'colorAddForm',
          'editColor'].includes($route.name )) ? 'active' : '')">
            <router-link to="/colorList">
              <span class="text">Өнгө</span>
            </router-link>
          </li>
          <li :class="(([
          'brandList',
          'brandAddForm',
          'editBrand'].includes($route.name )) ? 'active' : '')">
            <router-link to="/brandList">
              <span class="text">Брэндүүд</span>
            </router-link>
          </li>
          <li :class="(( [
          'userList'].includes($route.name )) ? 'active' : '')">
            <router-link to="/userList">
              <span class="text">Хэрэглэгч</span>
            </router-link>
          </li>
          <li :class="(( [
          'tagList',
          'addTag'].includes($route.name )) ? 'active' : '')">
            <router-link :to="{ path: '/tagList', query: { page: 1, size: 20, search: '', field: '', sort: '', type: '' } }">
              <span class="text">Tag</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li :class="(( [
      'productSupplierList',
      'products',
      'updateProduct',
      'addProduct',
      'inventory',
      'stockDetail',
      'transfer',
      'transferList',
      'addTransfer',
      'editTransfer',
      'transferDetail',
      'receiveTransferDetail',
      'return',
      'addReturn',
      'editReturn',
      'returnDetail',
      'receiveReturnDetail'].includes($route.name )) ? 'active': '')
      ">
        <router-link to="/products">
          <shopping-icon class="icon" :size= "24" />
          <span class="text">Бараа материал</span>
        </router-link>
        <ul>
          <li :class="(( [
          'products',
          'updateProduct',
          'addProduct'
          ].includes($route.name )) ? 'active': '')">
            <router-link to="/products">
              <span class="text">Бүтээгдэхүүн</span>
            </router-link>
          </li>
          <li :class="(( [
          'inventory',
          'stockDetail'
          ].includes($route.name )) ? 'active': '')">
            <router-link to="/inventory">
              <span class="text">Хувилбарууд</span>
            </router-link>
          </li>
          <li :class="(( [
          'transfer',
          'transferList',
          'addTransfer',
          'editTransfer',
          'transferDetail',
          'receiveTransferDetail'].includes($route.name )) ? 'active': '')">
        <router-link to="/transfer">
          <span class="text">Татан авалт</span>
        </router-link>
      </li>
        <li :class="(( [
        'productSupplierList',
        'updateProduct'
        ].includes($route.name )) ? 'active': '')">
          <router-link to="/productSupplierList">
            <span class="text">Хүлээгдэж буй</span>
          </router-link>
        </li>
        </ul>
      </li>
      <li :class="(( [
          'codeDiscount',
          'autoDiscount',
          'addDiscount'].includes($route.name )) ? 'active': '')">
        <router-link to="/code-discount">
          <coupon-icon class="icon" :size="20" />
          <span class="text">Хямдрал</span>
        </router-link>
        <ul>
          <li :class="(( [
          'codeDiscount'].includes($route.name )) ? 'active': '')">
            <router-link to="/code-discount">Код хямдрал</router-link>
          </li>
          <li :class="(( [
          'autoDiscount'].includes($route.name )) ? 'active': '')">
            <router-link to="/auto-discount">Автомат хямдрал</router-link>
          </li>
        </ul>
      </li>
      <li :class="(( [
          'bannerList',
          'addBanner'].includes($route.name )) ? 'active': '')">
        <router-link to="/banner-list">
          <newspaper-variant-outline class="icon" :size="20" />
          <span class="text">Сурталчилгаа</span>
        </router-link>
        <ul>
          <li :class="(( [
          'bannerList'].includes($route.name )) ? 'active': '')">
            <router-link to="/banner-list">Сурталчилгаа</router-link>
          </li>
        </ul>
      </li>
       <li :class="(( [
          'blockList',
          'addBlock'].includes($route.name )) ? 'active': '')">
        <router-link to="/block-list">
          <view-dashboard class="icon" :size="20" />
          <span class="text">Блок</span>
        </router-link>
        <ul>
          <li :class="(( [
          'blockList'].includes($route.name )) ? 'active': '')">
            <router-link to="/block-list">Блок бүтээгдэхүүн</router-link>
          </li>
        </ul>
      </li>
       <li :class="(( [
          'supplierAdminList',
          'editSupplierAdmin'].includes($route.name )) ? 'active': '')">
        <router-link to="/supplier-admin-list">
          <zend class="icon" :size="20" />
          <span class="text">Удирдлага</span>
        </router-link>
      </li>
      <li :class="(( [
          'supplierList'].includes($route.name )) ? 'active': '')">
        <router-link to="/supplierList">
          <currency-mnt-icon class="icon" :size="20" />
          <span class="text">Үнэ батлуулах хуудас</span>
        </router-link>
      </li>
      <li :class="(( [
          'collectionList',
          'addCollection',
          'collectionEdit'].includes($route.name )) ? 'active': '')">
        <router-link to="/collection-list">
          <counter-icon class="icon" :size="20" />
          <span class="text">Коллекци</span>
        </router-link>
      </li>
      <li :class="(( [
          'customerManagerList',
          'addCustomerManager',
          'customerManagerEdit'].includes($route.name )) ? 'active': '')">
        <router-link to="/customer-manager-list">
          <contacts-icon class="icon" :size="24" />
          <span class="text">Харилцагч хариуцсан менежер</span>
        </router-link>
      </li>
    </ul>
    <el-row align="center" class="mt10">
      <el-col class="text-center">
        <i :class="isSun ? 'el-icon-moon mr5 iconSun' : 'el-icon-moon mr5 iconDark'">
        </i>
        <el-switch v-model="isSun"></el-switch>
        <i :class="isSun ? 'el-icon-sunny ml5 iconSun' : 'el-icon-sunny ml5 iconDark'">
        </i>
      </el-col>
    </el-row>
  </el-aside>
</template>
<script>
import HomeIcon from 'vue-material-design-icons/Home.vue'
import PageNextIcon from 'vue-material-design-icons/PageNext.vue'
import RegistryIcon from 'vue-material-design-icons/City.vue'
import CouponIcon from 'vue-material-design-icons/TagMinus.vue'
import ContactsIcon from 'vue-material-design-icons/Contacts.vue'
import NewspaperVariantOutline from 'vue-material-design-icons/NewspaperVariantOutline.vue'
import ViewDashboard from 'vue-material-design-icons/ViewDashboard.vue'
import Zend from 'vue-material-design-icons/Zend.vue'
import ShoppingIcon from 'vue-material-design-icons/Shopping.vue'
import CurrencyMntIcon from 'vue-material-design-icons/CurrencyMnt.vue'
import CounterIcon from 'vue-material-design-icons/Counter.vue'
export default {
  components: {
    HomeIcon,
    PageNextIcon,
    RegistryIcon,
    CouponIcon,
    NewspaperVariantOutline,
    CurrencyMntIcon,
    Zend,
    ShoppingIcon,
    ViewDashboard,
    CounterIcon,
    ContactsIcon
  },
  data () {
    return {
      route: '',
      isSun: true
    }
  }
}
</script>
<style scoped>
.iconDark {
    color: white;
    font-size: 1rem;
}
  .iconSun {
    color: rgb(48, 65, 86);
    font-size: 1rem;
  }
</style>
