import { getToken } from '@/utils/auth.js'
export async function authHeader () {
  const token = await getToken()
  // const userName = await getUserName()
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token
    // user_name: userName
  }
}
