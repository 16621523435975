import config from '../config/index'
import { Message } from 'element-ui'
import { signOut } from '../utils/auth.js'
import axios from 'axios'
import { authHeader } from '../helpers/auth-header'
export const mainRequests = {
  get,
  post,
  customPost,
  customPostWithOption,
  customDelete,
  customPut,
  customGet,
  put,
  del,
  acceptedPrice
}
export const handlers = {
  alertReporter,
  timedRefresh,
  getBase64,
  checkImageSize
}

async function get (url) {
  const defaultHeader = await authHeader()
  const promise = await new Promise((resolve, reject) => {
    axios.get(config.BASE_PATH + url, { headers: defaultHeader })
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}

async function acceptedPrice (url, payload) {
  const defaultHeader = await authHeader()
  const promise = await new Promise((resolve, reject) => {
    axios.post(config.BASE_POST_ACCEPT_PRICE, payload, { headers: defaultHeader })
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}
async function post (url, payload) {
  const defaultHeader = await authHeader()
  const promise = await new Promise((resolve, reject) => {
    axios.post(config.BASE_PATH + url, payload, { headers: defaultHeader })
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}
async function customGet (url, payload) {
  const defaultHeader = await authHeader()
  const promise = await new Promise((resolve, reject) => {
    axios.get(url, { headers: defaultHeader })
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}
async function customDelete (url) {
  const defaultHeader = await authHeader()
  const promise = await new Promise((resolve, reject) => {
    axios.delete(url, { headers: defaultHeader })
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}

async function customPut (url, payload) {
  const defaultHeader = await authHeader()
  const promise = await new Promise((resolve, reject) => {
    axios.put(url, payload, { headers: defaultHeader })
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}

async function customPost (url, payload) {
  const defaultHeader = await authHeader()
  const promise = await new Promise((resolve, reject) => {
    axios.post(url, payload, { headers: defaultHeader })
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}

async function customPostWithOption (url, payload) {
  const tempOption = {
    responseType: 'json'
  }
  const promise = await new Promise((resolve, reject) => {
    axios.post(url, payload, tempOption)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error.response)
      })
  })
  return promise
}

async function del (url) {
  const defaultHeader = await authHeader()
  const promise = await new Promise((resolve, reject) => {
    axios.delete(config.BASE_PATH + url, { headers: defaultHeader })
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}

async function put (url, payload) {
  const defaultHeader = await authHeader()
  const promise = await new Promise((resolve, reject) => {
    axios.put(config.BASE_PATH + url, payload, { headers: defaultHeader })
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}

async function handleResponse (response) {
  if (response.status === 401) {
    signOut()
    timedRefresh()
  }
  return response.data
}

function alertReporter (message, type) {
  Message({
    message: message,
    duration: 2500,
    type: type,
    showClose: 1
  })
}

function timedRefresh () {
  setTimeout(() => {
    location.reload()
  }, 3000)
}

function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    let imgResult = ''
    reader.readAsDataURL(file)
    reader.onload = () => {
      imgResult = reader.result
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.onloadend = () => {
      resolve(imgResult.split(',')[1])
    }
  })
}

async function checkImageSize (imageFile, isSizeRequired, width, height) {
  return new Promise(function (resolve, reject) {
    if (imageFile.size / 1024 / 1024 < 1) {
      if (isSizeRequired) {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(imageFile.raw)
        img.onload = function () {
          const valid = img.width === width && img.height === height
          valid ? resolve() : reject(new Error(`Уучлаарай зурагны хэмжээ ${width}x${height} байх ёстой`))
        }
      } else {
        resolve()
      }
    } else {
      reject(new Error('Уучлаарай зурагны багтаамж ихдээ 1MB байх ёстой'))
    }
  })
}
