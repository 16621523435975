<template>
  <div id="app">
    <main-header />
    <el-container class="main-container">
      <main-sidebar />
      <el-main class="main-content">
        <router-view/>
      </el-main>
    </el-container>
    <main-chat/>
  </div>
</template>
<script>
import MainHeader from '../../components/mainHeader'
import MainSidebar from '../../components/mainSidebar.vue'
import mainChat from '../../components/mainChat'

export default {
  components: {
    MainHeader,
    MainSidebar,
    mainChat
  }
}
</script>
