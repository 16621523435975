var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-aside',{class:_vm.isSun ? 'main-sidebar' : 'main-sidebar1'},[_c('ul',{staticClass:"main-menu"},[_c('li',{class:((_vm.$route.name === 'home') ? 'active': '')},[_c('router-link',{attrs:{"to":"/"}},[_c('home-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Нүүр")])],1)],1),_c('li',{class:(( [
        'pageList',
        'addPage',
        'detailPage'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/page-list"}},[_c('page-next-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Хуудас")])],1)],1),_c('li',{class:(( [
        'category',
        'supplierAddForm',
        'warehouseList',
        'addWarehouse',
        'editWarehouse',
        'colorList',
        'tagList',
        'addTag',
        'brandList',
        'editSupplier',
        'brandAddForm',
        'editBrand',
        'supplier',
        'userList'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/category"}},[_c('registry-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Апп удирдлага")])],1),_c('ul',[_c('li',{class:(( ['category'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/category"}},[_c('span',{staticClass:"text"},[_vm._v("Категори")])])],1),_c('li',{class:(([
        'supplier',
        'editSupplier',
        'supplierAddForm'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/supplier"}},[_c('span',{staticClass:"text"},[_vm._v("Нийлүүлэгч")])])],1),_c('li',{class:(([
        'colorList',
        'colorAddForm',
        'editColor'].includes(_vm.$route.name )) ? 'active' : '')},[_c('router-link',{attrs:{"to":"/colorList"}},[_c('span',{staticClass:"text"},[_vm._v("Өнгө")])])],1),_c('li',{class:(([
        'brandList',
        'brandAddForm',
        'editBrand'].includes(_vm.$route.name )) ? 'active' : '')},[_c('router-link',{attrs:{"to":"/brandList"}},[_c('span',{staticClass:"text"},[_vm._v("Брэндүүд")])])],1),_c('li',{class:(( [
        'userList'].includes(_vm.$route.name )) ? 'active' : '')},[_c('router-link',{attrs:{"to":"/userList"}},[_c('span',{staticClass:"text"},[_vm._v("Хэрэглэгч")])])],1),_c('li',{class:(( [
        'tagList',
        'addTag'].includes(_vm.$route.name )) ? 'active' : '')},[_c('router-link',{attrs:{"to":{ path: '/tagList', query: { page: 1, size: 20, search: '', field: '', sort: '', type: '' } }}},[_c('span',{staticClass:"text"},[_vm._v("Tag")])])],1)])],1),_c('li',{class:(( [
    'productSupplierList',
    'products',
    'updateProduct',
    'addProduct',
    'inventory',
    'stockDetail',
    'transfer',
    'transferList',
    'addTransfer',
    'editTransfer',
    'transferDetail',
    'receiveTransferDetail',
    'return',
    'addReturn',
    'editReturn',
    'returnDetail',
    'receiveReturnDetail'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/products"}},[_c('shopping-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Бараа материал")])],1),_c('ul',[_c('li',{class:(( [
        'products',
        'updateProduct',
        'addProduct'
        ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/products"}},[_c('span',{staticClass:"text"},[_vm._v("Бүтээгдэхүүн")])])],1),_c('li',{class:(( [
        'inventory',
        'stockDetail'
        ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/inventory"}},[_c('span',{staticClass:"text"},[_vm._v("Хувилбарууд")])])],1),_c('li',{class:(( [
        'transfer',
        'transferList',
        'addTransfer',
        'editTransfer',
        'transferDetail',
        'receiveTransferDetail'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/transfer"}},[_c('span',{staticClass:"text"},[_vm._v("Татан авалт")])])],1),_c('li',{class:(( [
      'productSupplierList',
      'updateProduct'
      ].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/productSupplierList"}},[_c('span',{staticClass:"text"},[_vm._v("Хүлээгдэж буй")])])],1)])],1),_c('li',{class:(( [
        'codeDiscount',
        'autoDiscount',
        'addDiscount'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/code-discount"}},[_c('coupon-icon',{staticClass:"icon",attrs:{"size":20}}),_c('span',{staticClass:"text"},[_vm._v("Хямдрал")])],1),_c('ul',[_c('li',{class:(( [
        'codeDiscount'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/code-discount"}},[_vm._v("Код хямдрал")])],1),_c('li',{class:(( [
        'autoDiscount'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/auto-discount"}},[_vm._v("Автомат хямдрал")])],1)])],1),_c('li',{class:(( [
        'bannerList',
        'addBanner'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/banner-list"}},[_c('newspaper-variant-outline',{staticClass:"icon",attrs:{"size":20}}),_c('span',{staticClass:"text"},[_vm._v("Сурталчилгаа")])],1),_c('ul',[_c('li',{class:(( [
        'bannerList'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/banner-list"}},[_vm._v("Сурталчилгаа")])],1)])],1),_c('li',{class:(( [
        'blockList',
        'addBlock'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/block-list"}},[_c('view-dashboard',{staticClass:"icon",attrs:{"size":20}}),_c('span',{staticClass:"text"},[_vm._v("Блок")])],1),_c('ul',[_c('li',{class:(( [
        'blockList'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/block-list"}},[_vm._v("Блок бүтээгдэхүүн")])],1)])],1),_c('li',{class:(( [
        'supplierAdminList',
        'editSupplierAdmin'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/supplier-admin-list"}},[_c('zend',{staticClass:"icon",attrs:{"size":20}}),_c('span',{staticClass:"text"},[_vm._v("Удирдлага")])],1)],1),_c('li',{class:(( [
        'supplierList'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/supplierList"}},[_c('currency-mnt-icon',{staticClass:"icon",attrs:{"size":20}}),_c('span',{staticClass:"text"},[_vm._v("Үнэ батлуулах хуудас")])],1)],1),_c('li',{class:(( [
        'collectionList',
        'addCollection',
        'collectionEdit'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/collection-list"}},[_c('counter-icon',{staticClass:"icon",attrs:{"size":20}}),_c('span',{staticClass:"text"},[_vm._v("Коллекци")])],1)],1),_c('li',{class:(( [
        'customerManagerList',
        'addCustomerManager',
        'customerManagerEdit'].includes(_vm.$route.name )) ? 'active': '')},[_c('router-link',{attrs:{"to":"/customer-manager-list"}},[_c('contacts-icon',{staticClass:"icon",attrs:{"size":24}}),_c('span',{staticClass:"text"},[_vm._v("Харилцагч хариуцсан менежер")])],1)],1)]),_c('el-row',{staticClass:"mt10",attrs:{"align":"center"}},[_c('el-col',{staticClass:"text-center"},[_c('i',{class:_vm.isSun ? 'el-icon-moon mr5 iconSun' : 'el-icon-moon mr5 iconDark'}),_c('el-switch',{model:{value:(_vm.isSun),callback:function ($$v) {_vm.isSun=$$v},expression:"isSun"}}),_c('i',{class:_vm.isSun ? 'el-icon-sunny ml5 iconSun' : 'el-icon-sunny ml5 iconDark'})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }