<template>
  <el-header class="main-header">
    <el-row :gutter="0" align="middle">
      <el-col :span="12">
        <router-link to="/">
          <strong>Toktok Агуулах</strong>
        </router-link>
      </el-col>
      <el-col :span="12" class="text-right">
        <div class="profile-nav">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">{{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="logout" >Гарах</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
  </el-header>
</template>
<script>
import { signOut, getUserName } from '../utils/auth.js'
export default {
  data () {
    return {
      userName: '',
      role: '',
      isCollapse: false
    }
  },
  created () {
    getUserName().then(email => {
      this.userName = email.split('@')[0]
    })
    // getGroups().then(data => {
    //   this.role = data
    // })
  },
  methods: {
    logout () {
      signOut()
    }
  }
}
</script>
