import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import locale from 'element-ui/lib/locale/lang/mn'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import * as VueGoogleMaps from 'vue2-google-maps'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
import VueExcelXlsx from 'vue-excel-xlsx'
import { io } from 'socket.io-client'
import { getToken } from './utils/auth'
// your config here
Vue.use(VueExcelXlsx)
Vue.use(elTableInfiniteScroll)
Vue.use(ElementUI, { locale })
Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: 'AIzaSyB8wrdyLVTdvKFwWqAbzC-tlLgFoegQsDA',
    region: 'MN',
    language: 'mn',
    libraries: 'places'
  }
})
Vue.config.productionTip = false
Amplify.configure(awsconfig)
require('./assets/main.scss')

new Vue({
  router,
  data () {
    return {
      socket: null
    }
  },
  methods: {
    async initSocket () {
      const token = await getToken()
      const socket = io('chat-api.toktokmall.mn', {
        extraHeaders: {
          Authorization: token
        }
      })
      this.socket = socket
    },
    async socketTokenRefresher () {
      const token = await getToken()
      this.socket.io.opts.extraHeaders.Authorization = token
      setInterval(() => {
        this.socketTokenRefresher()
      }, 600000)
    }
  },
  store,
  render: h => h(App)
}).$mount('#app')
